/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1680px) {
	/* global
----------------------------------------------------------------------------------------------*/
	.after_clear:after,
	.wrapper:after,
	section:after {
		clear: both;
		display: block;
		content: " ";
	}
	.before_clear:before {
		clear: both;
		display: block;
		content: " ";
	}

	.wrapper {
		width: 1170px;
	}
	.container {
		width: 1170px;
	}

	.btn_box {
		span {
			font-size: 13px;
			padding: 0 30px;
		}
		&:after {
			font-size: 13px;
		}
	}

	.ic_box {
		font-size: 33px;
		line-height: 45px;
		&.topCent {
			padding-top: 25px;
		}
	}

	/* header
----------------------------------------------------------------------------------------------*/
	header {
		.wrapper {
			margin: 0 calc((100vw - 1200px) / 2);
			width: auto;
		}
		.logo {
			width: 140px;
			margin-top: 2px;
		}
		.main_head {
			margin-top: 0;
		}
		.nav {
			ul {
				li {
					margin-right: 25px;
					a {
						font-size: 14px;
					}
				}
			}
		}
		.lang {
			.act_lang {
				font: 14px;
			}
		}
		.bg_white {
			height: 90px;
		}
		.src .drop_src {
			height: 90px;
		}
		&.scroll-down {
			top: -90px;
		}
	}

	/* homepage
----------------------------------------------------------------------------------------------*/
	.sliderHome {
    padding: 90px 0 0; 
		.slider {
			.caption {
				width: 1170px;
				padding-left: 72px;
				h1,h2 {
					font-size: 36px;
					line-height: 120%;
					width: 460px;
				}
			}
			.slick-arrow {
				right: calc(((100vw - 1170px) / 2) - 60px);
				&.slick-prev {
					left: calc(((100vw - 1170px) / 2) - 60px);
				}
			}
      .images {
				img {
					height: calc(100svh - 90px);
				}
        video {
          height: calc(100svh - 90px);
        }
      }
		}
	}
	.nav_slideHome {
		top: 47%;
		@include transform(translateY(-47%));
		.slick-arrow {
			width: 40px;
			height: 40px;
			svg {
				height: 40px !important;
				width: 40px !important;
			}
		}
		.line {
			max-width: 150px;
			left: -30px;
		}
	}

	.higlight {
		height: 110px;
		.left {
			padding: 20px 50px 18px 0;
			width: 60.5%;
		}
		.slideNews {
			margin-left: 240px;
			.news {
				a {
					p {
						font-size: 14px;
					}
				}
			}
		}
		.title_ico {
			img {
				width: 45px;
			}
			.ic_box {
				font-size: 19px;
				line-height: 24px;
				margin-left: 65px;
			}
		}
		.right {
			padding: 30px 0 30px 25px;
			width: 39.5%;
			a {
				font-size: 13px;
				width: 210px;
			}
			.drop_ship {
				width: 185px;
				top: 100px;
				form {
					input[type="text"] {
						font-size: 14px;
					}
				}
			}
		}
	}

	.homeAbout {
		.txt_about {
			.rh_text {
				width: 550px;
				h1 {
					font-size: 24px;
					line-height: 120%;
				}
				// p {
				// 	font-size: 23px;
				// 	line-height: 26px;
				// }
				.lines {
					max-width: 230px;
					left: -150px;
				}
				.videos #vidAbout {
					height: 307px;
				}
			}
		}
		.img_about {
			img {
				width: 65%;
				max-width: 850px;
			}
		}
	}
	.businessHome {
		padding-top: 70px;
		.top_text {
			h1 {
				font-size: 33px;
				line-height: 45px;
			}
		}
		.ListBusiness {
			.item {
				.capt {
					left: 30px;
					right: 30px;
					h5 {
						font-size: 16px;
					}
				}
			}
		}
	}
	.top_text {
		p {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.cus_say {
		.container {
			margin-top: -100px;
			padding: 65px 0;
		}
		h3 {
			font-size: 28px;
		}
		.slider_customer {
			.slide {
				p {
					font-size: 16px;
					line-height: 24px;
				}
				.ket p {
					font-size: 14px;
				}
			}
		}
		.nav_slideCus {
			right: 10%;
			.slick-arrow {
				width: 40px;
				height: 40px;
				margin: 30px 0;
				&.slick-prev {
					svg {
						width: 40px !important;
						height: 40px !important;
					}
				}
			}
			&:before {
				right: -40px;
				width: 120px;
			}
		}
	}

	.homeNews {
		padding: 80px 0;
		.top_text {
			h1 {
				font-size: 33px;
				line-height: 35px;
			}
		}
		.listnews {
			.item {
				a {
					h6 {
						font-size: 16px;
						line-height: 24px;
					}
				}
				.read {
					font-size: 13px;
				}
			}
		}
	}

	.homeCareer {
		.txt_career {
			width: 430px;
			.lines {
				left: -50px;
				margin: 25px 0 30px;
				max-width: 150px;
			}
			p {
				font-size: 16px;
				line-height: 26px;
			}
		}
	}

	/* middle
----------------------------------------------------------------------------------------------*/

	.middle {
		padding: 90px 0;
		.wrapper {
			width: 1170px;
		}
	}
	.banner {
		.title_pg {
			h1,
			h2 {
				font-size: 40px;
			}
		}
	}
	.over_wrap {
		margin: 0 -170px 50px;
	}
	.title_content {
		h1 {
			padding-bottom: 25px;
			&:before {
				width: 120px;
			}
		}
	}

	.wrap_text {
		> h3 {
			font-size: 22px;
		}
	}

	.listing {
		.item {
			.ic_box {
				font-size: 18px;
				line-height: 26px;
				padding-top: 20px;
				margin-bottom: 15px;
			}
		}
		&.listService {
			.item {
				.img {
					width: 250px;
				}
				.text {
					width: 285px;
				}
				p {
					font-size: 14px;
					line-height: 22px;
					max-height: 88px;
				}
			}
		}
		&.listIntegrated {
			.item {
				h6 {
					line-height: 22px;
				}
			}
		}
		&.listWho,
		&.listCr {
			.dec {
				h6 {
					font-size: 24px;
					height: 52px;
				}
			}
		}
	}

	.sliderContent {
		.slick-arrow {
			width: 80px;
			height: 55px;
			&.slick-prev {
				right: 80px;
			}
		}
	}

	.sec_bottom {
		.ic_box {
			font-size: 33px;
			line-height: 45px;
		}
		.slideRecommend {
			margin: 0 30px;
			.item {
				width: 350px;
				margin-right: 25px;
				.text {
					padding: 25px 20px 10px;
					h6 {
						font-size: 20px;
					}
					p {
						font-size: 14px;
						line-height: 24px;
						max-height: 72px;
						overflow: hidden;
					}
				}
			}
			.slick-arrow {
				width: 50px;
				height: 50px;
				&.slick-prev {
					left: -80px;
				}
				&.slick-next {
					right: -80px;
				}
			}
		}
		.bg_recomnd {
			height: 57%;
		}
		&:after {
			height: 57%;
		}
	}

	.about_sec {
		padding: 60px 0;
		.text_about {
			.ic_box {
				padding-bottom: 20px;
			}
			p {
				font-size: 16px;
				line-height: 26px;
			}
		}
		&.who {
			.text_about {
				padding-right: 40px;
				width: 500px;
			}
		}
		&.compliance {
			min-height: 635px;
			.text_about {
				width: 520px;
				margin-top: 80px;
			}
		}
		.img_compli {
			width: 55%;
			max-width: 750px;
		}
		&.group {
			.wrap_text {
				p {
					font-size: 16px;
					line-height: 26px;
				}
			}
		}
		&.respons {
			min-height: 700px;
			.text_about {
				width: 530px;
			}
		}
		.img_respons {
			width: 660px;
		}
		&:last-of-type {
			margin-bottom: -90px;
		}
	}

	.sliderGroup {
		margin: 40px 30px 0;
		.slide {
			width: 350px;
			.dec {
				padding: 20px 20px 0;
				h6 {
					font-size: 20px;
					line-height: 24px;
					height: 48px;
				}
				p {
					font-size: 14px;
					line-height: 22px;
					height: 88px;
				}
			}
		}
		.slick-arrow {
			width: 50px;
			height: 50px;
			&.slick-prev {
				left: -80px;
			}
			&.slick-next {
				right: -80px;
			}
		}
	}

	.listVisi {
		h3 {
			font-size: 24px;
		}
	}

	.listFact {
		.item {
			h2 {
				font-size: 40px;
			}
		}
	}

	#map_logistic {
		height: 500px;
	}

	.subCompliance {
		.box {
			h5 {
				font-size: 24px;
			}
		}
	}

	.content_Compliance {
		.tab_nav {
			.tab {
				h5 {
					font-size: 24px;
				}
			}
		}
		.box_white {
			.content_tab {
				> h3 {
					font-size: 24px;
				}
			}
		}
	}

	.healthlist {
		.item {
			.dec {
				margin-right: 5%;
			}
		}
	}

	.slideCSR {
		.sliderCapt {
			.capt {
				padding-top: 80px;
				h2 {
					font-size: 25px;
				}
			}
		}
		.slick-arrow {
			width: 80px;
			height: 55px;
			&.slick-prev {
				right: 80px;
			}
		}
	}

	.accordion {
		.title_acc {
			.ic_plus {
				width: 15px;
				height: 15px;
				&:after {
					width: 3px;
				}
				&:before {
					height: 3px;
				}
			}
		}
	}
	.slideMangement {
		.slide {
			figure {
				width: 320px;
			}
			.txt {
				margin-left: 420px;
				width: 570px;
			}
		}
		.slick-arrow {
			width: 40px;
			height: 40px;
			svg {
				width: 40px !important;
				height: 40px !important;
			}
		}
	}

	.sec_news {
		padding: 55px 40px;
		.img_news {
			width: 47%;
			position: relative;
			left: 0;
			img {
				position: absolute;
				max-width: 700px;
				width: auto;
				top: 50px;
			}
		}
		.text_news {
			width: 50%;
			padding-right: 0;
			.ic_box {
				padding-bottom: 15px;
				margin-bottom: 25px;
			}
			p {
				font-size: 16px;
				line-height: 26px;
			}
			.newslisting {
				.list {
					padding: 0 30px 20px;
				}
			}
		}
		&.indust {
			.img_news {
				img {
					left: auto;
					right: 0;
				}
			}
			.text_news {
				padding-left: 0;
			}
		}
	}

	.txt_filter {
		.left {
			width: 70%;
			p {
				font-size: 16px;
				line-height: 26px;
			}
		}
		.right {
			.not_src {
				+ .select2 {
					.select2-selection {
						height: 55px;
					}
					.select2-selection__rendered {
						padding: 13px 55px 13px 30px;
					}
					.select2-selection__arrow {
						width: 55px;
						height: 54px;
					}
				}
			}
		}
	}

	.pg_career {
		height: auto;
		overflow: hidden;
		.text_career {
			left: 40px;
			width: 500px;
			h3 {
				font-size: 36px;
				line-height: 45px;
			}
		}
		.img_carrer {
			width: 850px;
			margin-bottom: -80px;
			margin-right: -50px;
		}
	}

	#id_contactMap {
		height: 650px;
	}
	.map_ket {
		width: 450px;
		padding: 50px;
		left: 3%;
		.ic_box:before {
			width: 100px;
		}
		h6 {
			font-size: 16px;
		}
		p {
			font-size: 16px;
			line-height: 24px;
		}

		.bx_btn {
			left: 50px;
			right: 50px;
			bottom: -30px;
			a {
				font-size: 18px;
				width: 100px;
				line-height: 60px;
				background-size: 60px !important;
			}
		}
	}

	.btm_contact {
		.text_contact {
			left: 40px;
			width: 45%;
			.ic_box {
				font-size: 24px;
				line-height: 32px;
				margin-bottom: 10px;
			}
		}
		.lf_contact {
			.text_contact {
				left: 40px;
			}
		}
		.rh_contact .img {
			right: 0px;
		}
		.img {
			@include transform(scale(0.9));
			bottom: -30px;
			right: 15px;
		}
	}

	#map_office {
		height: 650px;
	}
	.nav_sub.office ul li a {
		font-size: 16px;
	}

	.inquiry .txt_filter .right .not_src + .select2 .select2-selection {
		height: 54px;
	}
	/* footer
----------------------------------------------------------------------------------------------*/

	footer {
		h4 {
			font-size: 16px;
		}
		.top_footer {
			padding: 50px 0 40px;
			.wrapper {
				width: auto;
				margin: 0 40px;
			}
			.foot_addrs {
				.lgo {
					width: 130px;
				}
				.txt {
					margin-left: 185px;
				}
			}
			.main_foot {
				.col {
					width: 155px;
					ul {
						li {
							a {
								line-height: 18px;
							}
						}
					}
				}
			}
		}
		.btm_footer {
			min-height: 75px;
			.wrapper {
				width: auto;
				margin: 0 40px;
			}
		}
	}
} /* end media */




/* desktop < 1400 ----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1383px) {
	.sliderHome {
    .slider {
      .images {
        video {
        }
      }
    }
  }

  .full-section {
    figcaption {
      width: 500px; 
    }
  }
  

}
